import { Typography, Box, Hidden } from "@mui/material";
import Footer from "../component/Footer";
import Header from "../component/Header";
import CustomerTestimonials from "../component/CustomerTestimonials";
import Support from "../component/Support";
import { display } from "@mui/system";
import { supportQuestions } from "../utils/supportQuestions";

const Testimonials = () => {
  return (
    <Box style={{ backgroundColor: "#FEFBF6" }}>
      <Header />
      <Box
        sx={{
          textAlign: "center",
          padding: { xs: "60px 24px", md: "80px 370px" },
          pb: { xs: "60px", md: "160" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box style={{ display: "flex", alignItems: "end" }}>
          <Hidden mdDown>
            <img
              src="images/testimonialHeader1.png"
              alt="testimonialHeader1"
            ></img>
          </Hidden>
          <Typography
            sx={{
              fontFamily: "GilroyMedium",
              fontWeight: 700,
              fontSize: 65,
              lineHeight: "75px",
              ml: { xs: 0, md: "45px" },
            }}
          >
            Testimonials
          </Typography>
        </Box>

        <Typography
          sx={{
            fontFamily: "GilroyMedium",
            fontWeight: 500,
            fontSize: 22,
            lineHeight: "26px",
            mt: "26px",
            textAlign: "center",
          }}
        >
          At ClinicalPad, data security isn't an afterthought, it's our knight
          in shining armor. Your information rests easy in our secure digital
          vault.
          <Hidden mdDown>
            <Box sx={{ pl: { xs: 0, md: "24px" } }}>
              <img
                src="images/testimonialHeader2.png"
                alt="testimonialHeader2"
                style={{
                  display: "flex",
                  marginLeft: "auto",
                  left: "60px",
                  top: "-20px",
                  position: "relative",
                }}
              ></img>
            </Box>
          </Hidden>
        </Typography>
      </Box>

      <CustomerTestimonials />
      <Support supportQuestions={supportQuestions}/>
      <Footer />
    </Box>
  );
};

export default Testimonials;
