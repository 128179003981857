import React from "react";
import { Grid, Typography, Box } from "@mui/material";

function HighlightSection() {
  return (
    <Box
      className="container"
      sx={{
        paddingTop: { xs: "80px" },
        paddingBottom: { xs: "80px" },
      }}
    >
      <Typography
        variant="h2"
        sx={{
          textAlign: "center",
          marginBottom: "40px",
          fontFamily: "GilroyMedium",
          fontWeight: 700,
          fontSize: { xs: "30px", md: "45px" },
          lineHeight: { xs: "30px", md: "50px" },
        }}
      >
        Highlights
      </Typography>

      <Grid container spacing={4}>
        {/* Left side cards */}
        <Grid item xs={12} md={4} className="centerAlign">
          <img
            src={"images/home/highlight1.png"}
            alt={"heading1"}
            style={{ maxWidth: "100%", height: "-webkit-fill-available" }}
          />
        </Grid>

        {/* Right side cards */}
        <Grid item xs={12} md={8} className="centerAlign">
          <Grid container spacing={4}>
            {/* Cards on top right */}
            <Grid item xs={12} className="centerAlign">
              <Grid container spacing={4}>
                {/* First card on top */}
                <Grid item xs={12} md={6} className="centerAlign">
                  <img
                    src={"images/home/highlight2.png"}
                    alt={"heading2"}
                    style={{
                      // maxWidth: "100%",
                      // height: "-webkit-fill-available",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Grid>
                {/* Second card on top */}
                <Grid item xs={12} md={6} className="centerAlign">
                  <img
                    src={"images/home/highlight3.png"}
                    alt={"heading3"}
                    style={{
                      // maxWidth: "100%",
                      // height: "-webkit-fill-available",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Card on bottom right */}
            <Grid
              item
              xs={12}
              sx={{ display: { xs: "none", md: "flex" } }}
              className="centerAlign"
            >
              <img
                src={"images/home/highlight4.png"}
                alt={"heading4"}
                style={{
                  // maxWidth: "100%",
                  // height: "-webkit-fill-available",
                  width: "100%",
                  height: "100%",
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: { xs: "flex", md: "none" } }}
              className="centerAlign"
            >
              <img
                src={"images/home/highlightXS4.png"}
                alt={"heading4"}
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default HighlightSection;
