import React from "react";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
} from "@mui/material";

const cardData = [
  {
    imageUrl: "images/home/benefit1.jpg",
    title: "Easier Clinic Management",
    description:
      "ClinicalPad has integrated all paper-based tasks on a comprehensive platform. The Health Workforce can draft customised patient reports, letters, and prescriptions. It enables them to create, maintain, and upgrade patient records at their fingertips!",
  },
  {
    imageUrl: "images/home/benefit2.jpg",
    title: "Intuitive Dashboard",
    description:
      "ClinicalPad’s intuitive dashboard offers handy features. It provides patient updates and a summary of the tasks created, assigned, and delivered. The user can also search specific records. The platform's features are simple to use and navigate.",
  },
  {
    imageUrl: "images/home/benefit3.jpg",
    title: "Multiple Themes and Layout",
    description:
      "ClinicalPad empowers caregivers to select from the multitude of creative style templates in the gallery after composing their letters. They can choose a specific layout for the letter's content to further refine the document.",
  },
];
const BenefitsSection = () => {
  return (
    <Box
      className="container"
      sx={{
        // paddingLeft: { xs: "24px", md: "100px" },
        // paddingRight: { xs: "24px", md: "100px" },
        paddingTop: { xs: "80px" },
        paddingBottom: { xs: "80px" },
      }}
    >
      <Typography
        variant="h2"
        sx={{
          textAlign: "center",
          marginBottom: "40px",
          fontFamily: "GilroyMedium",
          fontWeight: 700,
          fontSize: { xs: "30px", md: "45px" },
          lineHeight: { xs: 1.3, md: "50px" },
        }}
      >
        Benefits
      </Typography>
      <Grid container spacing={{ xs: 6, md: 4 }}>
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card sx={{ boxShadow: "none", backgroundColor: "#FCF5E9" }}>
              <CardMedia
                component="img"
                image={card.imageUrl}
                alt={card.title}
              />
            </Card>
            <Box>
              <Typography
                gutterBottom
                component="div"
                sx={{
                  marginTop: "21px",
                  fontFamily: "GilroyMedium",
                  fontWeight: 700,
                  fontSize: { xs: "20px", md: "26px" },
                  lineHeight: { xs: 1.2 },
                }}
              >
                {card.title}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  marginTop: { xs: 0, md: "21px" },
                  fontWeight: 500,
                  fontSize: "16px",
                  fontHeight: "21px",
                }}
              >
                {card.description}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default BenefitsSection;
