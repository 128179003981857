import { Typography, Hidden, Box, Button, Grid } from "@mui/material";
import Footer from "../component/Footer";
import Header from "../component/Header";
import Support from "../component/Support";
import { securitySupportQuestions } from "../utils/supportQuestions";

const securityIcons = [
  {
    url: "images/security1.svg",
    title: "Data Sharing",
    description: "We never sell or share your data.",
  },
  {
    url: "images/security2.svg",
    title: "Your Data",
    description:
      "We simply store and protect your data, but you remain the owner.",
  },
  {
    url: "images/security3.svg",
    title: "Encryption",
    description:
      "We use end-to-end encryption and SSL/TLS protocols to transmit and store your data securely.",
  },
  {
    url: "images/security4.svg",
    title: "Safe & Secure Partners",
    description:
      "We ensure our partners are safe and secure with robust security standards.",
  },
  {
    url: "images/security5.svg",
    title: "Restricted",
    description:
      "All your data will only be visible to you and us. At no point will your data be accessible to third parties.",
  },
  {
    url: "images/security6.svg",
    title: "Trained Staff",
    description:
      "All our employees are trained in Privacy and Data Security in accordance with GDPR standards.",
  },
];

const Security = () => {
  const renderCard = ({ url, title, description }) => {
    return (
      <Grid item xs={12} md={4}>
        <img src={url} alt={title} />
        <Typography
          sx={{
            fontFamily: "GilroyMedium",
            fontWeight: 700,
            fontSize: { xs: "20px", md: "26px" },
            lineHeight: { xs: 1.2 },
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            fontFamily: "GilroyMedium",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "21px",
          }}
        >
          {description}
        </Typography>
      </Grid>
    );
  };

  return (
    <Box style={{ backgroundColor: "#FEFBF6" }}>
      <Header />

      {/* SECTION 1 */}
      <Box
        className="container"
        sx={{
          textAlign: "center",
          pt: { xs: "60px", md: "80px" },
          pb: { xs: "60px", md: "160" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box style={{ display: "flex", alignItems: "end" }}>
          <Hidden mdDown>
            <img src="images/securityHeader1.png" alt="header1" />
          </Hidden>
          <Typography
            sx={{
              fontFamily: "GilroyMedium",
              fontWeight: 700,
              fontSize: { xs: "40px", md: "65px" },
              lineHeight: { xs: 1.4, md: "75px" },
              ml: { xs: 0, md: "45px" },
            }}
          >
            Data's Security
          </Typography>
        </Box>

        <Typography
          sx={{
            fontFamily: "GilroyMedium",
            fontWeight: 500,
            fontSize: 22,
            lineHeight: "26px",
            mt: "26px",
            textAlign: "center",
          }}
        >
          ClinicalPad is designed and developed with data security and
          confidentiality in mind. Security isn't just fine print for us. It's
          something we take very seriously to ensure all our users' information
          and their patients' data are secure at all times.
          <Hidden mdDown>
            <Box>
              <img
                src="images/testimonialHeader2.png"
                alt="testimonialHeader2"
                style={{
                  display: "flex",
                  marginLeft: "auto",
                  left: "60px",
                  top: "-20px",
                  position: "relative",
                }}
              ></img>
            </Box>
          </Hidden>
        </Typography>
      </Box>

      <Box className="container">
        {/* SECTION 2 */}
        <Typography
          sx={{
            fontFamily: "GilroyMedium",
            fontWeight: 700,
            fontSize: { xs: "30px", md: "45px" },
            lineHeight: { xs: 1.3, md: "50px" },
            mb: { xs: "20px", md: "74px" },
          }}
        >
          Securing Your Data
        </Typography>
      </Box>

      <Box
        className="container"
      >
        <Grid container spacing={{ xs: 4, md: 3 }}>
          {securityIcons?.map((item) => {
            return renderCard(item);
          })}
        </Grid>
      </Box>

      {/* SECTION 3 */}
      <Box
        className="container"
        sx={{
          gap: "24px",
          display: "flex",
          flexDirection: "column",
          // mx: { xs: "24px", md: "104px" },
          mt: { xs: "60px", md: "120px" },
          backgroundColor: "#FCF5E9",
          py: { xs: "40px", md: "80px" },
        }}
      >
        <Typography
          sx={{
            fontFamily: "GilroyMedium",
            fontWeight: 700,
            fontSize: { xs: "30px", md: "45px" },
            lineHeight: { xs: 1.3, md: "50px" },
            textAlign: "center",
            // px: { xs: "24px", md: "350px" },
          }}
        >
          Fully GDPR Compliant
        </Typography>

        <Box
          sx={{
            flexGrow: 0,
            justifyContent: "center",
            display: "none",
          }}
        >
          <Button
            variant="contained"
            style={{
              borderRadius: "100px",
              padding: "11px 32px 11px 32px",
              backgroundColor: "#171819",
              color: "#fff",
              textTransform: "none",
              fontSize: "16px",
              fontWeight: 700,
              boxShadow: "none",
              fontFamily: "PlusJakartaSans",
            }}
            // onClick={() => {
            //   window.open("https://staging.clinicalpad.com/sign-up/clinic");
            // }}
          >
            Accept All Cookies
          </Button>
          <Button
            variant="outlined"
            style={{
              marginLeft: 8,
              borderRadius: "100px",
              padding: "11px 32px 11px 32px",
              borderColor: "#171819",
              color: "#171819",
              textTransform: "none",
              fontSize: "16px",
              fontWeight: 700,
              boxShadow: "none",
              fontFamily: "PlusJakartaSans",
            }}
            onClick={() => {}}
          >
            Cookie Setting
          </Button>
        </Box>
      </Box>

      <Support supportQuestions={securitySupportQuestions} />
      <Footer />
    </Box>
  );
};

export default Security;
