import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "./pages/Home";
import Product from "./pages/Product";
import AboutUs from "./pages/AboutUs";
import Testimonials from "./pages/Testimonials";
import Security from "./pages/Security";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicies from "./pages/PrivacyPolicies";
import DataProcessingAgreement from "./pages/DataProcessingAgreement";
import DataUsage from "./pages/DataUsage";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop /> {/* Add ScrollToTop component here */}
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/product" element={<Product />} />
        <Route exact path="/aboutUs" element={<AboutUs />} />
        <Route exact path="/testimonials" element={<Testimonials />} />
        <Route exact path="/security" element={<Security />} />
        <Route exact path="/termsAndConditions" element={<TermsConditions />} />
        <Route exact path="/privacyPolicy" element={<PrivacyPolicies />} />
        <Route
          exact
          path="/dataProcessingAgreement"
          element={<DataProcessingAgreement />}
        />
        <Route exact path="/dataGuidlines" element={<DataUsage />} />
      </Routes>
    </Router>
  );
}

export default App;
