import { Typography, Hidden, Box } from "@mui/material";
import Footer from "../component/Footer";
import Header from "../component/Header";
import DocumentTable from "../component/DocumentView";
import dataUsageGuidelines from "../utils/dataUsageGuidelines.json";
import TermsCondition from "../component/TermsCondition";

const DataUsage = () => {
  return (
    <Box style={{ backgroundColor: "#FEFBF6" }}>
      <Header />

      {/* SECTION 1 */}
      <Box
        sx={{
          textAlign: "center",
          pt: { xs: "30px", md: "40px" },
          pb: { xs: "20px", md: "40px" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          className="container"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Hidden mdDown>
            <img src="images/termsConditionsHeader.png" alt="header1"></img>
          </Hidden>
          <Typography
            sx={{
              fontFamily: "GilroyMedium",
              fontWeight: 700,
              fontSize: { xs: "40px", md: "65px" },
              lineHeight: { xs: 1.4, md: "75px" },
              ml: { md: 5 },
            }}
          >
            Data Usage Guidelines
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography
          className="container"
          sx={{
            fontFamily: "GilroyMedium",
            fontWeight: 500,
            fontSize: 18,
            lineHeight: "32px",
            pb: "20px",
          }}
          dangerouslySetInnerHTML={{
            __html:
              '<p><span style="font-weight: 400;">This document outlines the guidelines and practices governing the collection, processing, and usage of data for the purpose of training and generating clinical documentation by ClinicalPad.</span></p>' +
              "<p></p>" +
              '<p><span style="font-weight: 400;">For a comprehensive breakdown of the privacy of data and terms of use, please see our </span><strong>Privacy Policy</strong><span style="font-weight: 400;"> and </span><strong>Terms and Conditions</strong><span style="font-weight: 400;"> documents.</span></p>' +
              "<p></p>" +
              '<p><span style="font-weight: 400;">We collect and process the following types of data when using ClinicalPad.</span></p>',
          }}
        />
      </Box>
      <TermsCondition data={dataUsageGuidelines} />

      <Footer />
    </Box>
  );
};

export default DataUsage;
