import React, { useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import { Typography, Box, Hidden } from "@mui/material";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MyAccordion from "./Accordian";

const TermsCondition = ({ data }) => {
  const [selectedItem, setSelectedItem] = useState(data[0]);
  const [toggle, setToggle] = useState(true);

  const containerRef = useRef(null);

  const handleItemClick = (item, isToggle) => {
    setToggle(isToggle);
    setSelectedItem(item);
  };

  return (
    <Box className="container" sx={{ pb: { xs: "24px", md: "120px" } }}>
      <Hidden mdUp>
        {data.map((item, index) => {
          return (
            <MyAccordion
              key={index}
              label={
                <Box key={item.id}>
                  <Typography
                    sx={{
                      fontFamily: "GilroyMedium",
                      fontWeight: 700,
                      fontSize: 16,
                      lineHeight: "19px",
                      color: "#0F0F10",
                    }}
                  >
                    {item.title}
                  </Typography>
                </Box>
              }
              description={[]}
              descriptionNode={
                <Typography
                  sx={{
                    fontFamily: "GilroyMedium",
                    fontWeight: 500,
                    fontSize: 18,
                    lineHeight: "32px",
                    mt: { xs: "-15px", md: 0 },
                  }}
                  dangerouslySetInnerHTML={{ __html: item.detail }}
                />
              }
            />
          );
        })}
      </Hidden>

      <Hidden mdDown>
        <Grid container spacing={0}>
          {/* Left side - linkable text */}
          <Grid
            item
            xs={12}
            md={3}
            style={{
              borderRight: "1px solid #ccc",
            }}
          >
            {data.map((item) => (
              <Box key={item.id} sx={{ mb: "16px" }}>
                <Typography
                  onClick={() =>
                    handleItemClick(item, item.subtitles?.length > 0)
                  }
                  sx={{
                    cursor: "pointer",
                    fontFamily: "GilroyMedium",
                    fontWeight: selectedItem?.id === item.id ? 700 : 500,
                    fontSize: 16,
                    lineHeight: "19px",
                    mb: selectedItem.id === item.id ? "10px" : "0px",
                    color: selectedItem?.id === item.id ? "#0F0F10" : "#6C6778",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      cursor:
                        item.subtitles?.length > 0 ? "pointer" : "default",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (item.subtitles?.length > 0) {
                        handleItemClick(
                          item,
                          selectedItem?.id === item.id ? !toggle : true
                        );
                      }
                    }}
                  >
                    {
                      <span>
                        {selectedItem?.id === item.id &&
                        toggle &&
                        item.subtitles?.length > 0 ? (
                          <KeyboardArrowDownIcon />
                        ) : (
                          <KeyboardArrowRightIcon />
                        )}
                      </span>
                    }
                  </Box>
                  {item.title}
                </Typography>
                {item?.subtitles?.length > 0 &&
                  toggle &&
                  selectedItem?.id === item.id && (
                    <ul
                      style={{
                        fontFamily: "GilroyMedium",
                        fontWeight: 700,
                        fontSize: 16,
                        lineHeight: "19px",
                        margin: "3px",
                      }}
                    >
                      {item.subtitles.map((subtitle, index) => (
                        <li
                          key={index}
                          id={`subtitle_${index}`}
                          style={{
                            paddingBottom: "10px",
                            fontWeight: 500,
                            color: "#6C6778",
                          }}
                        >
                          {subtitle}
                        </li>
                      ))}
                    </ul>
                  )}
              </Box>
            ))}
          </Grid>

          {/* Right side - description */}
          <Grid
            item
            xs={12}
            md={9}
            sx={{ paddingLeft: { xs: "24px", md: "60px" } }}
            ref={containerRef}
          >
            {selectedItem && (
              <TermsConditionDetail selectedItem={selectedItem} />
            )}
          </Grid>
        </Grid>
      </Hidden>
    </Box>
  );
};

export default TermsCondition;

const TermsConditionDetail = ({ selectedItem }) => {
  return (
    <>
      <Typography
        sx={{
          fontFamily: "GilroyMedium",
          fontWeight: 700,
          fontSize: 32,
          lineHeight: "50px",
          mb: { xs: "12px", md: "24px" },
        }}
      >
        {selectedItem.title}
      </Typography>
      <Divider
        orientation="horizontal"
        sx={{ mb: { xs: "20px", md: "40px" } }}
      />
      <Typography
        sx={{
          fontFamily: "GilroyMedium",
          fontWeight: 500,
          fontSize: 18,
          lineHeight: "32px",
        }}
        dangerouslySetInnerHTML={{ __html: selectedItem.detail }}
      />
    </>
  );
};
