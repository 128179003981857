import React from "react";
import { Typography, Hidden, Box, Divider, Grid } from "@mui/material";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import MyAccordion from "./Accordian";

const Support = ({ supportQuestions }) => {
  return (
    <Box className="container">
      <Typography
        sx={{
          fontFamily: "GilroyMedium",
          fontWeight: 700,
          fontSize: { xs: "30px", md: "45px" },
          lineHeight: { xs: 1.3, md: "50px" },
          pt: { xs: "60px", md: "120px" },
          pb: { xs: "20px", md: "32px" },
          textAlign: "center",
        }}
      >
        Help, Resources, and Support
      </Typography>

      <Typography
        sx={{
          fontFamily: "GilroyMedium",
          fontWeight: 500,
          fontSize: 22,
          lineHeight: "32px",
          // padding: { xs: "0px 24px", md: "0px 380px" },
          pb: { xs: "30px", md: 0 },
          textAlign: "center",
        }}
      >
        Find the answers you need to help leverage the full potential of
        ClinicalPad.
      </Typography>

      {/* <Hidden mdDown>
        <Divider
          sx={{
            borderColor: "##CFCAD1",
            mt: "80px",
            // mx: "300px",
            mb: "84px",
          }}
        />
      </Hidden> */}

      <Box
        sx={{
          // mx: { xs: "24px", md: "420px" },
          mt: "30px",
          mb: { xs: "60px", md: "120px" },
        }}
      >
        {supportQuestions.map((item, index) => {
          return (
            <MyAccordion
              key={index}
              label={item.label}
              description={item.description}
              descriptionNode={item?.descriptionNode}
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default Support;
