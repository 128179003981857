// In Product.js
import React from "react";
import Header from "../component/Header";
import ProductImageWithText from "../component/ProductImageWithText";
import Box from "@mui/material/Box";
import JoinProduct from "../component/JoinProduct";
import ContactUs from "../component/ContactUs";
import Footer from "../component/Footer";
import { Grid, Typography } from "@mui/material";
import ProductFeature from "../component/ProductFeature";
import ProductLetters from "../component/ProductLetters";

const Product = () => {
  const redirectToIndividual = () => {};
  return (
    <Box style={{ backgroundColor: "#FEFBF6" }}>
      <Header />
      <Box
        sx={{
          backgroundColor: "#FEFBF6",
        }}
      >
        <ProductImageWithText
          imageUrl="images/product/product1.png"
          heading="Using Our Highly Advanced Platform, Reinvent Clinical Communication!"
          paragraph="ClinicalPad spares time using artificial intelligence (AI) to swiftly create clinical letters. Our simple interface provides efficient support to medical professionals all over the globe."
          imagePosition="right"
          backgroundColor="#FEFBF6"
          hideButton={true}
          gridImage={6}
          gridText={6}
          paddingBottom={{ xs: "20px", md: "20px" }}
          redirectToIndividual={() => redirectToIndividual()}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: "#FCF5E9",
        }}
      >
        <ProductImageWithText
          imageUrl="images/product/product2.png"
          heading="Creating and Tracking a Task"
          paragraph={`ClinicalPad helps clinicians create tasks for staff. The platform's transparent interface makes task management smooth. From task creation to its completion, each stage can be tracked and updated effortlessly.`}
          imagePosition="right"
          backgroundColor="#FCF5E9"
          hideButton={true}
          gridImage={6}
          gridText={6}
          paddingBottom={{ xs: "20px", md: "0px" }}
          redirectToIndividual={() => redirectToIndividual()}
        />
      </Box>

      <Box sx={{ backgroundColor: "#FCF5E9" }}>
        <Box className="container">
          <Grid
            container
            spacing={4}
            sx={{
              paddingBottom: { xs: "60px", md: "140px" },
            }}
          >
            <Grid item xs={12} md={4}>
              <img
                src="images/Layout.svg"
                alt="AllDone"
                style={{ marginTop: "5px" }}
              />
              <Typography
                sx={{
                  fontFamily: "GilroyMedium",
                  fontWeight: 700,
                  fontSize: { xs: "20px", md: "26px" },
                  lineHeight: { xs: 1.2 },
                  margin: {xs: "10px 0px", md: "21px 0px"},
                }}
              >
                Generate Document
              </Typography>
              <Typography
                sx={{
                  fontFamily: "GilroyMedium",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "21px",
                }}
              >
                Simply start by generating the document of choice.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <img src="images/Template.svg" alt="AllDone" />
              <Typography
                sx={{
                  fontFamily: "GilroyMedium",
                  fontWeight: 700,
                  fontSize: { xs: "20px", md: "26px" },
                  lineHeight: { xs: 1.2 },
                  margin: {xs: "10px 0px", md: "21px 0px"},
                }}
              >
                Assign Task
              </Typography>
              <Typography
                sx={{
                  fontFamily: "GilroyMedium",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "21px",
                }}
              >
                Assign documents to invited team members with a custom task message.
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <img src="images/AllDone.svg" alt="AllDone" />
              <Typography
                sx={{
                  fontFamily: "GilroyMedium",
                  fontWeight: 700,
                  fontSize: { xs: "20px", md: "26px" },
                  lineHeight: { xs: 1.2 },
                  margin: {xs: "10px 0px", md: "21px 0px"},
                }}
              >
                Manage Task
              </Typography>
              <Typography
                sx={{
                  fontFamily: "GilroyMedium",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "21px",
                }}
              >
                Prioritise, track and manage all assigned tasks.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <ProductLetters />
      <ProductFeature />
      {/* <CustomerReviews /> */}
      <Box sx={{ mt: "40px" }}>
        <JoinProduct />
      </Box>
      <ContactUs />
      <Footer />
    </Box>
  );
};

export default Product;
