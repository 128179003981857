import React from "react";
import Button from "@mui/material/Button";
import { Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";

function ProductImageWithText(props) {
  const {
    imageUrl,
    heading,
    paragraph,
    imagePosition,
    backgroundColor,
    hideButton,
    gridImage,
    gridText,
    buttonText,
    paddingBottom,
  } = props;

  const renderImage = () => {
    return (
      <img
        src={imageUrl}
        alt={heading}
        style={{
          // width: "-webkit-fill-available",
          // height: "-webkit-fill-available",
          width: "100%",
          height: "100%",
        }}
      />
    );
  };

  const renderText = () => {
    return (
      <>
        <Typography
          gutterBottom
          sx={{
            fontFamily: "GilroyMedium",
            fontWeight: 700,
            fontSize: { xs: "30px", md: "45px" },
            lineHeight: { xs: 1.3, md: "50px" },
          }}
        >
          {heading}
        </Typography>

        {/* Paragraph */}
        <Typography
          variant="body1"
          paragraph
          style={{
            fontFamily: "GilroyMedium",
            fontWeight: 500,
            fontSize: "16PX",
            lineHeight: "21px",
          }}
        >
          {paragraph}
        </Typography>

        {/* Explore More button */}
        {hideButton ? null : (
          <Button
            variant="contained"
            style={{
              borderRadius: "100px",
              padding: "11px 32px",
              backgroundColor: "#171819",
              color: "#fff",
              textTransform: "none",
              fontSize: "16px",
              fontWeight: 700,
              boxShadow: "none",
              fontFamily: "PlusJakartaSans",
            }}
            component={Link}
            to="/product"
          >
            {buttonText || "Explore More"}
          </Button>
        )}
      </>
    );
  };

  return (
    <Box className="container">
      <Grid
        key={heading}
        container
        alignItems="center"
        justifyContent="center"
        sx={{
          paddingTop: { xs: "40px", md: "0px" },
          paddingBottom: paddingBottom,
          // px: { xs: "24px", md: "227px" },
        }}
        spacing={{ xs: 0, md: 3 }}
      >
        {/* Right side content */}
        {imagePosition === "left" && (
          <>
            <Grid item xs={12} md={gridImage}>
              {renderImage()}
            </Grid>
            <Grid item xs={12} md={gridText}>
              {renderText()}
            </Grid>
          </>
        )}

        {/* Left side content */}
        {imagePosition === "right" && (
          <>
            <Grid item xs={12} md={gridText}>
              {renderText()}
            </Grid>
            <Grid item xs={12} md={gridImage}>
              {renderImage()}
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
}

export default ProductImageWithText;
