import { Typography, Box } from "@mui/material";

const ProductFeature = () => {
  return (
    <Box sx={{ backgroundColor: "#FCF5E9" }}>
      <Box
        className="container"
        sx={{
          gap: "24px",
          display: "flex",
          flexDirection: "column",
          pt: { xs: "80px" },
          pb: { xs: "80px" },
        }}
      >
        <Typography
          sx={{
            marginBottom: { xs: "0px", md: "40px" },
            fontFamily: "GilroyMedium",
            fontWeight: 700,
            textAlign: "center",
            fontSize: { xs: "30px", md: "45px" },
            lineHeight: { xs: 1.3, md: "50px" },
          }}
        >
          Our Features
        </Typography>
        <img
          src="images/product/product6.png"
          alt="features"
          style={{
            width: "100%",
            height: "100%",
          }}
        ></img>
      </Box>
    </Box>
  );
};

export default ProductFeature;
