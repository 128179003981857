import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SubscribeDialogue from "../SubscribeDialogue";

const FirstSection = () => {
  const [openModal, setOpenModal] = React.useState(false);

  const redirectToIndividual = () => {};
   
  const handleRedirectSignup = () => {
    window.location.href = 'https://app.clinicalpad.com/hospital'; 
  };
  return (
    <Box
      className="container"
      sx={{
        gap: "32px",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          margin: "0 15px",
          justifyContent: "center",
          marginTop: "68px",
          // paddingLeft: { xs: "24px", md: "0px" },
          // paddingRight: { xs: "24px", md: "0px" },
        }}
      >
        <Typography
          style={{
            fontFamily: "GilroyMedium",
            fontWeight: 700,
            fontSize: "16px",
          }}
        >
          What’s new
        </Typography>
        <Divider
          orientation="vertical"
          sx={{
            margin: "0 15px",
            width: "unset",
            height: "22px",
            display: { xs: "none", md: "block" },
          }}
        />
        <Typography
          style={{
            fontFamily: "GilroyMedium",
            fontWeight: 500,
            fontSize: "16px",
          }}
        >
          Reinventing Clinical Communication with our innovative platform!
        </Typography>
        <ArrowForwardIcon
          sx={{ fontSize: "16px", color: "#0F0F10", marginLeft: 2 }}
        />
      </Box>

      <Typography
        sx={{
          fontFamily: "GilroyMedium",
          fontWeight: 700,
          fontSize: { xs: "35px", md: "95px" },
          lineHeight: { xs: 1.3, md: "103px" },
          letterSpacing: "-0.01em",
        }}
      >
        A New Way To
        <Typography
          sx={{
            fontFamily: "GilroyMedium",
            fontWeight: 700,
            fontSize: { xs: "35px", md: "95px" },
            lineHeight: { xs: 1.4, md: "103px" },
            letterSpacing: "-0.01em",
          }}
        >
          Create Clinical Letters
        </Typography>
      </Typography>
      <Typography
        sx={{
          fontFamily: "GilroyMedium",
          fontWeight: 500,
          fontSize: { xs: "18px", md: "22px" },
          lineHeight: { xs: "21px", md: "26.69px" },
          textAlign: "center",
          // paddingLeft: { xs: "24px", md: "240px" },
          // paddingRight: { xs: "24px", md: "240px" },
        }}
      >
        ClinicalPad uses AI to create clinical letters with patient notes
        instantly, saving you valuable time. Our user-friendly interface
        provides efficient and reliable service to healthcare providers
        worldwide!
      </Typography>

      <Box sx={{ flexGrow: 0, marginBottom: { xs: "60px", md: "68px" } }}>
        <Button
          variant="contained"
          style={{
            borderRadius: "100px",
            padding: "11px 32px 11px 32px",
            backgroundColor: "#171819",
            color: "#fff",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: 700,
            boxShadow: "none",
            fontFamily: "PlusJakartaSans",
          }}
          onClick={handleRedirectSignup}
        >
          Start Free
        </Button>
        <Button
          variant="outlined"
          style={{
            marginLeft: 8,
            borderRadius: "100px",
            padding: "11px 32px 11px 32px",
            borderColor: "#171819",
            color: "#171819",
            textTransform: "none",
            fontSize: "16px",
            fontWeight: 700,
            boxShadow: "none",
            fontFamily: "PlusJakartaSans",
            display: "none",
          }}
          onClick={() => redirectToIndividual()}
        >
          Contact sales
        </Button>
      </Box>
      <SubscribeDialogue open={openModal} onClose={() => setOpenModal(false)} />
    </Box>
  );
};

export default FirstSection;
