import React from "react";
import { Box } from "@mui/system";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";

const CustomRating = ({ value }) => {
  const filledStars = Math.floor(value);
  const hasHalfStar = value % 1 !== 0;

  const renderStars = () => {
    const stars = [];

    // Render filled stars
    stars.push(
      ...Array(filledStars)
        .fill()
        .map((_, index) => <StarIcon key={index} sx={{ color: "#F5B640" }} />)
    );

    // Render half star if needed
    if (hasHalfStar) {
      stars.push(<StarHalfIcon key="half-star" sx={{ color: "#F5B640" }} />);
    }

    // Render remaining empty stars
    stars.push(
      ...Array(5 - stars.length)
        .fill()
        .map((_, index) => (
          <StarIcon key={filledStars + index} sx={{ color: "#F5B640" }} />
        ))
    );

    return stars;
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>{renderStars()}</Box>
  );
};

export default CustomRating;
