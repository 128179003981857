import React from "react";
import "react-multi-carousel/lib/styles.css";
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Grid,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const CarouselComponent = ({ cardsData, activeSlide, setActiveSlide, md }) => {
  const renderCard = (cardData, index) => {
    return (
      <Grid item xs={12} sm={12} md={md}>
        {/* <Card
          key={cardData.title + index}
          sx={{ boxShadow: "none", backgroundColor: "#FEFBF6" }}
        > */}
          <img
            src={cardData.imageUrl}
            alt={cardData.title}
            style={{
              // width: "100%",
              // height: "100%"
            }}
          />
          {/* <CardContent> */}
            <Typography
              sx={{
                fontFamily: "GilroyMedium",
                fontWeight: 700,
                fontSize: { xs: "20px", md: "26px" },
                lineHeight: { xs: 1.2 },
                mt: { xs: "10px" },
                mb: { xs: "10px" },
              }}
            >
              {cardData.title}
            </Typography>
            <Typography
              sx={{
                fontFamily: "GilroyMedium",
                fontWeight: 500,
                fontSize: 16,
                lineHeight: "21px",
              }}
            >
              {cardData.description}
            </Typography>
          {/* </CardContent> */}
          {/* <CardActions>
            <Button
              onClick={() => {}}
              sx={{
                marginBottom: "60px",
                color: "#1C1C1C",
                textTransform: "none",
                fontFamily: "GilroyMedium",
                fontWeight: 700,
                fontSize: 18,
                lineHeight: "22px",
                alignItems: "center",
                // display: "flex",
                display: "none",
                justifyContent: "center",
              }}
            >
              Explore More
              <ArrowForwardIcon
                sx={{ fontSize: "18px", color: "#0F0F10", marginLeft: 2 }}
              />
            </Button>
          </CardActions> */}
        {/* </Card> */}
      </Grid>
    );
  };

  return (
    <Grid container spacing={{ xs: 6, md: 4 }}>
      {cardsData.map((cardData, index) => {
        return renderCard(cardData, index);
      })}
    </Grid>
  );
};

export default CarouselComponent;
