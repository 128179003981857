import {
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  Box,
  Rating,
} from "@mui/material";
import SubscribeDialogue from "./SubscribeDialogue";
import { useState } from "react";

const JoinProduct = () => {
  const [openModal, setOpenModal] = useState(false);

  const handleRedirectSignup = () => {
    window.location.href = 'https://app.clinicalpad.com/hospital'; 
  };
  const redirectToIndividual = () => {};
  return (
    <Box className="container" sx={{ backgroundColor: "#FCF5E9" }}>
      <Box
        sx={{
          gap: "24px",
          display: "flex",
          flexDirection: "column",
          padding: { xs: "40px 24px", md: "80px 185px" },
          // margin: { xs: "60px 24px", md: "120px 104px" },
        }}
      >
        <Typography
          sx={{
            fontFamily: "GilroyMedium",
            fontWeight: 700,
            fontSize: { xs: "30px", md: "45px" },
            lineHeight: { xs: 1.3, md: "50px" },
            textAlign: "center",
            padding: { xs: "0px", md: "0px 50px" },
          }}
        >
          Join a Global Community of Clinicians Using ClinicalPad
        </Typography>
        <Typography
          sx={{
            fontFamily: "GilroyMedium",
            fontWeight: 500,
            fontSize: { xs: 18, md: 22 },
            lineHeight: { xs: "21px", md: "32px" },
            textAlign: "center",
          }}
        >
          ClinicalPad: The modern clinician's essential tool. Say goodbye to
          clunky dictation devices, costly transcriptions, and time-consuming
          tasks. With AI and ML, create professional clinical letters from
          patient  in seconds.
        </Typography>

        <Box sx={{ flexGrow: 0, display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            style={{
              borderRadius: "100px",
              padding: "11px 32px 11px 32px",
              backgroundColor: "#171819",
              color: "#fff",
              textTransform: "none",
              fontSize: "16px",
              fontWeight: 700,
              boxShadow: "none",
              fontFamily: "PlusJakartaSans",
            }}
            onClick={handleRedirectSignup}
          >
            Start Free
          </Button>
          <Button
            variant="outlined"
            style={{
              marginLeft: 8,
              borderRadius: "100px",
              padding: "11px 32px 11px 32px",
              borderColor: "#171819",
              color: "#171819",
              textTransform: "none",
              fontSize: "16px",
              fontWeight: 700,
              boxShadow: "none",
              fontFamily: "PlusJakartaSans",
              display: "none",
            }}
            onClick={() => redirectToIndividual()}
          >
            View Pricing
          </Button>
        </Box>
        <SubscribeDialogue
          open={openModal}
          onClose={() => setOpenModal(false)}
        />
      </Box>
    </Box>
  );
};

export default JoinProduct;
