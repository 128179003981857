import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Chip,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MailchimpForm from "./MailchimpForm";

const SubscribeDialogue = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{
        sx: {
          backgroundColor: "#fff",
          border: "1px solid #CFCAD1",
          boxShadow: "0px 10px 30px 0px #00000026",
          borderRadius: "16px",
        },
      }}
    >
      <DialogTitle sx={{ backgroundColor: "rgba(249, 237, 215, 0.8)" }}>
        <Box sx={{ position: "relative" }}>
          <Typography
            sx={{
              fontFamily: "GilroyMedium",
              fontSize: "24px",
              fontWeight: "700",
              lineHeight: "29px",
              color: "#0F0F10",
              pt: "16px",
            }}
          >
            Subscribe
          </Typography>
          <IconButton
            aria-label="close"
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              backgroundColor: "#C1BAAE",
            }}
            onClick={onClose}
          >
            <CloseIcon sx={{ fontSize: "15px", color: "#0F0F10" }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ backgroundColor: "rgba(249, 237, 215, 0.8)" }}>
        <MailchimpForm /> {/* Use the MailchimpForm component */}
      </DialogContent>
    </Dialog>
  );
};

export default SubscribeDialogue;
