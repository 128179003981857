import React, { useState } from "react";
import { Typography, Box } from "@mui/material";
import CarouselComponent from "../CarouselComponent";

const cardsData = [
  {
    imageUrl: "images/aboutSection5a.svg",
    title: "Put the customer first",
    description:
      "The architects of ClinicalPad developed a platform that is more than a mere online document editor. The user-friendly interface of this amazing application reduced the time needed to devise medical documents. ClinicalPad has immensely broadened the templates available. Along with clinical letters, they now include a diverse range of clinical documents, such as SOAP notes, H&P notes, discharge summaries, etc. Everything from voice-to-text dictation to customised templates and teamwork tools is intended to enhance the productivity of medical offices.",
  },
  {
    imageUrl: "images/aboutSection5b.svg",
    title: "If we do it, we nail it",
    description:
      "The remodeled ClinicalPad has been skilled with special training using massive medical data from the UK. This guarantees that it continually generates accurate and professional documentation. It also ensures that it is unaffected by possible language barriers and that there are no hurdles to affect the quality of medical records. This is a major factor that is extremely valuable for practitioners in areas where English may not be the primary language.",
  },
  {
    imageUrl: "images/aboutSection5c.svg",
    title: "Do the thing",
    description:
      "The launch of ClinicalPad has resulted in a staggering 85% reduction in medical transcription expenses, a testament to its significant impact. It has revolutionised efficiency and productivity in healthcare facilities, proving its potential to transform medical procedures worldwide.",
  },
  {
    imageUrl: "images/aboutSection5d.svg",
    title: "Stay humble",
    description:
      "ClinicalPad is more than just an application; it's a giant leap towards better, faster, and more reliable healthcare documentation. This transformation was driven by the innovative thinking and leadership of our co-founders, Amri Shafeek and Omar Mowlana, and their exceptional team. We invite you to join us in shaping the future of healthcare, one document at a time.",
  },
];

const SecondSection = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const handleSlideChange = (index) => {
    setActiveSlide(index);
  };

  return (
    <Box
      className="container"
      sx={{
        display: "flex",
        flexDirection: "column",
        pt: { xs: "60px", md: "120px" },
        pb: { xs: "60px", md: "120px" },
      }}
    >
      <Typography
        sx={{
          fontFamily: "GilroyMedium",
          fontWeight: 700,
          fontSize: { xs: "30px", md: "45px" },
          lineHeight: { xs: 1.3, md: "50px" },
        }}
      >
        Our Values Aren’t
      </Typography>
      <Typography
        sx={{
          fontFamily: "GilroyMedium",
          fontWeight: 700,
          fontSize: { xs: "30px", md: "45px" },
          lineHeight: { xs: 1.3, md: "50px" },
        }}
      >
        Window Dressing
      </Typography>
      <Typography
        sx={{
          marginBottom: "60px",
          fontFamily: "GilroyMedium",
          fontWeight: 500,
          fontSize: 16,
          lineHeight: "21px",
          marginTop: "24px",
          textAlign: "start",
          // marginRight: { xs: "24px", md: "600px" },
        }}
      >
        Revolutionize Healthcare Documentation with ClinicalPad: Streamlined,
        Accurate, and Cost-Efficient Solution.
      </Typography>
      <CarouselComponent
        md={3}
        cardsData={cardsData}
        activeSlide={activeSlide}
        setActiveSlide={handleSlideChange}
      />
    </Box>
  );
};

export default SecondSection;
