import {
  Typography,
  Button,
  Box,
  Grid,
  TextField,
  Snackbar,
  SnackbarContent,
} from "@mui/material";
import { useState } from "react";

const textFieldStyle = {
  "& .MuiOutlinedInput-root": {
    borderRadius: "100px",
    backgroundColor: "#fff",
    "& fieldset": {
      borderRadius: "100px",
    },
  },
  "& .MuiOutlinedInput-input": {
    borderRadius: "100px",
    fontFamily: "GilroyMedium",
    fontWeight: 500,
    fontSize: 16,
    lineHeight: "19px",
    borderColor: "#CCCCCC",
    padding: "16px 22px",
  },
};
const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [openToast, setOpenToast] = useState(false);
  const [toast, setToast] = useState({ message: "", color: "#4caf50" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "valueeee");
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const openToastMessage = (content) => {
    setToast(content);
    setOpenToast(true);
  };

  const closeToast = () => {
    setOpenToast(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://staging.clinicalpad.com/backend/apis/v1/genral/contact-us",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        setFormData({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
        openToastMessage({
          message: "Form Submitted successfully",
          color: "#4caf50",
        });
      } else {
        throw new Error("Failed to submit form");
      }
    } catch (error) {
      openToastMessage({
        message: "Failed to submit form. Please try again later.",
        color: "#ff3333",
      });
    }
  };

  return (
    <Box
      sx={{
        gap: "24px",
        display: "flex",
        flexDirection: "column",
        pt: { xs: "60px" },
        alignItems: "center",
      }}
    >
      <Typography
        className="container"
        sx={{
          fontFamily: "GilroyMedium",
          fontWeight: 700,
          textAlign: "center",
          fontSize: { xs: "30px", md: "45px" },
          lineHeight: { xs: 1.3, md: "50px" },
          // padding: { xs: "0px 24px", md: "0px 399px" },
        }}
      >
        Join a Community of Clinicians Who Are Simplifying Their Clinical
        Documentation Processes
      </Typography>
      <Typography
        className="container"
        sx={{
          fontFamily: "GilroyMedium",
          fontWeight: 500,
          fontSize: { xs: 22, md: 18 },
          lineHeight: "32px",
          textAlign: "center",
          // padding: { xs: "0px 60px", md: "0px 293px" },
          paddingBottom: { xs: "40px", md: "80px" },
        }}
      >
        Thank you for your interest in contacting us. Please fill out the
        contact form below, and our team will get back to you as soon as
        possible. We strive to respond to all enquiries within 48 hours.
      </Typography>

      <Grid container spacing={8}>
        <Grid item xs={12} md={5} sx={{ mb: { xs: "0px", md: "48px" } }}>
          <Box
            sx={{
              pl: "24px",
              pr: { xs: "24px", md: "0px" },
              display: "flex",
              justifyContent: { xs: "center", md: "flex-end" },
            }}
          >
            <form onSubmit={handleSubmit} style={{ maxWidth: "390px" }}>
              <TextField
                label="First Name"
                variant="outlined"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                required
                margin="normal"
                sx={textFieldStyle}
              />
              <TextField
                label="Email Address"
                variant="outlined"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                required
                margin="normal"
                sx={textFieldStyle}
              />
              <TextField
                label="Subject"
                variant="outlined"
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                fullWidth
                required
                margin="normal"
                sx={textFieldStyle}
              />
              <TextField
                label="Type Your Message"
                variant="outlined"
                multiline
                rows={4}
                name="message"
                value={formData.message}
                onChange={handleChange}
                fullWidth
                required
                margin="normal"
                sx={{
                  ...textFieldStyle,
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: "#fff",
                    borderRadius: "16px",
                    "& fieldset": {
                      borderRadius: "16px",
                    },
                  },
                }}
              />

              <Button
                type="submit"
                variant="contained"
                sx={{
                  borderRadius: "100px",
                  padding: "11px 32px 11px 32px",
                  backgroundColor: "#171819",
                  color: "#fff",
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: 700,
                  boxShadow: "none",
                  fontFamily: "PlusJakartaSans",
                  mt: "32px",
                  width: { xs: "100%", md: "unset" },
                }}
              >
                Submit
              </Button>
            </form>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            paddingBottom: "0px",
            paddingTop: "0px",
            display: { xs: "flex", md: "none" },
          }}
        >
          <img
            src="images/home/homeXS5.png"
            alt="contact"
            style={{
              // width: "-webkit-fill-available",
              // height: "-webkit-fill-available",
              width: "100%",
              height: "100%",
            }}
          />
        </Grid>

        <Grid
          item
          xs={12}
          md={7}
          sx={{
            paddingBottom: "0px",
            display: { xs: "none", md: "flex" },
            justifyContent: "flex-end",
          }}
        >
          <img
            src="images/home/home5.jpg"
            alt="contact"
            style={{
              borderTopLeftRadius: "20px",
              // width: "-webkit-fill-available",
              // height: "-webkit-fill-available",
              width: "100%",
              height: "100%",
              boxShadow:
                "0px 10px 50px 0px #0000001A, 0px 20px 30px 0px #0000001A, 0px 0px 1px 0px #00000080",
            }}
          />
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={openToast}
        autoHideDuration={3000}
        onClose={closeToast}
      >
        <SnackbarContent
          message={toast.message}
          style={{ backgroundColor: toast.color }}
        />
      </Snackbar>
    </Box>
  );
};

export default ContactUs;
