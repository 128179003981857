import { Typography } from "@mui/material";

export const supportQuestions = [
  {
    label: "What is ClinicalPad?",
    descriptionNode: null,
    description: [
      "ClinicalPad is an AI powered web application that is designed to create clinical documents using patient notes.",
    ],
  },
  {
    label: "Who owns ClinicalPad?",
    descriptionNode: null,
    description: [
      "ClinicalPad is owned by ClinicalPad Ltd, a UK based technology company.",
    ],
  },
  {
    label: "Where is ClinicalPad based?",
    descriptionNode: null,
    description: [
      "ClinicalPad is based in London, United Kingdom. Our team however is fully remote and based around the world.",
    ],
  },
  {
    label: "Does ClinicalPad have an app for phones, tablets and laptops?",
    descriptionNode: null,
    description: [
      "We do not have an app at this stage. However this is something we look to implement in the near future. ClinicalPad is predominantly designed to be used on a laptop or desktop. However, can be used on mobile phones and tablets as well.",
    ],
  },
  {
    label: "Does ClinicalPad integrate with any EMS/EMR systems?",
    descriptionNode: null,
    description: [
      "We do not offer integrations to any EMS/EMR providers at this stage. This is however a feature we are looking to implement in the near future.",
    ],
  },
  {
    label: "How much does ClinicalPad cost?",
    descriptionNode: null,
    description: [
      "At this point in time, ClinicalPad is completely free to use with no hidden costs. No credit/debit card details are required when signing up. Simply sign-up with your information and start using the application straight away.We hope to introduce monthly and yearly subscription options in the near future. We will be notifying all our users in advance of any changes to our pricing plan. For the time being, you get to enjoy ClinicalPad completely free.",
    ],
  },
  {
    label: "Can I import patient records to the application?",
    descriptionNode: null,
    description: [
      "We do not offer this feature at this point in time. Another feature we hope to introduce very soon.",
    ],
  },
  {
    label: "Is ClinicalPad similar to EMS/EMR systems?",
    descriptionNode: null,
    description: [
      "Not at all. ClinicalPad is not designed to replace any EMS/EMR system. This is simply a writing tool packed with features to help clinicians create and manage clinical letters.",
    ],
  },
  {
    label:
      "I currently use medical transcription services to generate clinical letters. How does ClinicalPad differ?",
    descriptionNode: null,
    description: [
      "Traditional transcription services can be costly, have long turnaround times and sometimes contain inaccurate information. In addition, clinicians have to spend additional time recording voice notes/ dictations and then uploading or sending them securely to their transcription service. ClinicalPad takes away all this hassle from the clinician by allowing the user to simply type in the patient notes they would usually take down while with the patient and simply click on the ‘Generate Letter’ button to generate a professional clinical letter with their customised letterhead and signature all intact ready to be shared in only a matter of seconds.",
    ],
  },
  {
    label:
      "How efficient and accurate are the letters generated by ClinicalPad?",
    descriptionNode: null,
    description: [
      "ClinicalPad uses Artificial intelligence and Machine learning to generate clinical letters. The application was initially trained on thousands of anonymised patient notes and clinical letters. As a new application, ClinicalPad is continuously learning with every patient note typed and every letter it generates. At its early stages, the generated letters may have to be edited to meet certain expectations. But as time goes on and our users continue to use this application, the generated letters become smarter, accurate and efficient.",
    ],
  },
  {
    label: "Can I edit the the generated letters?",
    descriptionNode: null,
    description: [
      "Absolutely. All generated letters will be presented to you in an editable screen where you can customise and edit the letter as required. Once done, you can preview your letter and either print, download or share via email.",
    ],
  },
  {
    label: "What type of medical documentation does ClinicalPad generate?",
    descriptionNode: null,
    description: [
      "Currently the application generates clinical letters. We will be implementing other medical documentation in the near future.",
    ],
  },
  {
    label: "Can I use ClinicalPad in my country?",
    descriptionNode: null,
    description: [
      "Yes, ClinicalPad is open to all users, regardless of their location.",
    ],
  },
  {
    label:
      "I have questions around data usage and security of the system. Where can I find out more?",
    descriptionNode: null,
    description: [
      "You can find out more on data usage and security of ClinicalPad on our Security page on our website. In addition, you can always view our Privacy policy and Data usage policy for more information.",
    ],
  },
];

export const securitySupportQuestions = [
  {
    label: "How do I know ClinicalPad is safe for my use?",
    descriptionNode: null,
    description: [
      "At ClinicalPad we take data security and confidentiality very seriously. In fact, we’ve built this platform taking no shortcuts in terms of data security and confidentiality.",
      " We transfer and store data in encrypted form. This means that, no one else can access and/or read the data without the required credentials. All our data is stored securely with our partner Amazon Web Services (AWS).",
    ],
  },
  {
    label: "Where is my data stored?",
    descriptionNode: null,
    description: [
      "We use Amazon Web Services (AWS) servers to store all your data. AWS is the industry leader when it comes to secure data storage and is used by major corporations to safely store data.",
      "For the purpose of data storage, all data are stored securely in our AWS servers located in the United Kingdom. For the purpose of generating letters using AI, our AI partner Open AI is located in the United States. So, while non-identifiable information will not be saved with Open AI, this information will transit through US servers in order to generate a response.",
      "If that sounds like a bunch of nonsensical jargon to you, here’s what it means: all data shared between you and ClinicalPad is transmitted and stored securely in our secure servers. No one can read or access the information except for you and us.",
    ],
  },
  {
    label: "Who has access to my data?",
    descriptionNode: null,
    description: [
      "Your data is only accessible to yourself (The account owner) and us. Once the data is added to ClinicalPad, it is securely stored in our servers with no other points of access.",
    ],
  },
  {
    label: "How long is my data stored for?",
    descriptionNode: null,
    description: [
      "As long as you continue to maintain an account with us, your data will be stored and made available to you. Should you however delete a record or cancel your subscription, then the data will be instantly deleted and cannot be retrieved.",
      "We hope to implement a data saving feature in the near future.",
    ],
  },
  {
    label: "How will my data be kept safe if I’m using AI to generate letters?",
    descriptionNode: null,
    description: [
      "Many AI writing tools/applications in the market cannot be used for patient sensitive material. This is because these tools use the data you enter, to train and fine-tune their AI systems to increase its usability.",
      "ClinicalPad does exactly this but with safeguards in place to ensure all our users can add all the required information to generate a clinical letter without compromising the safety and confidentiality of the entered data. In fact, we built ClinicalPad using thousands of anonymised clinical letters and notes to ensure the privacy and confidentiality of the used data. We have designated areas to add patient identifiable information which is stored in a dedicated safe AWS server in encrypted form and will not be used to train and fine-tune the system. All other non-identifiable information added to the system will be used to train and fine-tune the system to provide better quality letters every time you use ClinicalPad.",
    ],
  },
  {
    label: "How will my data be managed?",
    descriptionNode: null,
    description: [
      "In addition to being fully vetted, all our staff are trained in data security and privacy in accordance with GDPR standards. Which means your data is always handled with the utmost confidentiality.",
      "We never sell or share identifiable or non-identifiable data. While you have complete access to your data, as a further security measure, we only have limited access to any identifiable data you enter. This means, while we have access to your data, it is only limited, always keeping you in full control of your data.",
      "We do however use non-identifiable data entered into ClinicalPad to train and fine-tune the application to help the application constantly learn from your input and provide better quality letters as you continue to use it. Importantly, we also use non-identifiable data to derive important medical trends. More information on this can be found on the following FAQ.",
    ],
  },
  {
    label: "How do you use non-identifiable data?",
    descriptionNode: null,
    description: [
      "Non-identifiable data is never shared or sold. We use this data to train and fine-tune the application to help the application constantly learn from your input and provide better quality letters as you continue to use it.",
      "We also use this data for a few important causes. We use this type of data to understand important medical trends in terms of diseases, causes, accuracy of diagnoses, develop preventative care and many more. This important information is gathered by collecting and analysing the data. Only the results from this analysis is then shared with medical bodies, research organisations and others to assist with developing better care for patients across the world.",
    ],
  },
  {
    label: "What happens to my data when I delete a record?",
    descriptionNode: null,
    description: [
      "When you delete a record, your data will not be saved and will be lost. While we hope to implement a feature to recover deleted records in the near future, this option is not currently available.",
      "When deleting a record, it’s important to know that this record will no longer be available.",
    ],
  },
  {
    label: "What happens to my data when I cancel my subscription?",
    descriptionNode: null,
    description: [
      "When you cancel your subscription, all your data will be lost. We hope to implement a feature to recover cancelled subscriptions in the near future.",
    ],
  },
  {
    label: "What can I do to keep my data safe?",
    description: [],
    descriptionNode: (
      <Typography
        sx={{
          fontFamily: "GilroyMedium",
          fontWeight: 500,
          fontSize: "18px",
          lineHeight: "25px",
        }}
      >
        While keeping your data safe is our topmost priority, data security
        starts with you. Following the below Safe Use points will ensure you are
        doing your part to keep your data safe and secure at all times.
        <br />
        <ul>
          <li>
            <strong>Keep your browser and device up to date:</strong>
            <br />
            This prevents any unwanted malware entering your browser or device
            and ultimately ClinicalPad as a result. In fact, this should be a
            standard practice for any application you may use.
          </li>
          <br />
          <li>
            <strong>Create strong passwords:</strong>
            <br />
            Creating a strong password cannot be underestimated. Creating and
            maintaining a strong password will prevent criminals from guessing
            and entering your account.
          </li>
          <br />
          <li>
            <strong>Understanding user roles:</strong>
            <br />
            Understanding and managing user roles will ensure you provide access
            to confidential information only to those who need it.
          </li>
          <br />
          <li>
            <strong>Do not share your password:</strong> <br />
            Under no circumstance do we encourage sharing your password. Should
            you wish to provide a user access to your account, you can do this
            by inviting them and controlling what they have access to.
          </li>
          <br />
        </ul>
      </Typography>
    ),
  },
];
