import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
  Box,
  Button,
  Hidden,
} from "@mui/material";
import CustomRating from "./CustomRating";

const firstColumnData = [
  {
    title: "Kathryn Murphy",
    position: "Strategic Advisor",
    company: " Oprah Winfrey Network",
    review:
      "Mauris porttitor, neque feugiat scelerisque consequat , metus dui vehicula tellus, ac tincidunt turpis ligula ut massa. Cras sed rhoncus turpis. Vestibulum sodales est non leo placerat bibendum at ultricies risus. Nulla iaculis lacinia finibus. Sed sit amet metus dignissim, feugiat metus a, pulvinar lectus. Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    rating: 4.5,
    type: "text",
  },
  {
    type: "image",
    title: "Security & Confidentiality",
    imageUrl: "images/testimonial1.png",
  },
  {
    title: "Kathryn Murphy",
    position: "Strategic Advisor",
    company: " Oprah Winfrey Network",
    review:
      "Mauris porttitor, neque feugiat scelerisque consequat , metus dui vehicula tellus, ac tincidunt turpis ligula ut massa. Cras sed rhoncus turpis. Vestibulum sodales est non leo placerat bibendum at ultricies risus. Nulla iaculis lacinia finibus. Sed sit amet metus dignissim, feugiat metus a, pulvinar lectus. Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    rating: 4.5,
    type: "text",
  },
  {
    title: "Kathryn Murphy",
    position: "Strategic Advisor",
    company: " Oprah Winfrey Network",
    review:
      "Mauris porttitor, neque feugiat scelerisque consequat , metus dui vehicula tellus, ac tincidunt turpis ligula ut massa. Cras sed rhoncus turpis. Vestibulum sodales est non leo placerat bibendum at ultricies risus. Nulla iaculis lacinia finibus. Sed sit amet metus dignissim, feugiat metus a, pulvinar lectus. Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    rating: 4.5,
    type: "text",
  },
  {
    type: "gradient",
    degree: 0,
  },
];

const secondColumnData = [
  {
    type: "gradient",
    degree: 180,
  },
  {
    title: "Kathryn Murphy",
    position: "Strategic Advisor",
    company: " Oprah Winfrey Network",
    review:
      "Mauris porttitor, neque feugiat scelerisque consequat , metus dui vehicula tellus, ac tincidunt turpis ligula ut massa. Cras sed rhoncus turpis. Vestibulum sodales est non leo placerat bibendum at ultricies risus. Nulla iaculis lacinia finibus. Sed sit amet metus dignissim, feugiat metus a, pulvinar lectus. Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    rating: 4.5,
    type: "text",
  },
  {
    title: "Kathryn Murphy",
    position: "Strategic Advisor",
    company: " Oprah Winfrey Network",
    review:
      "Mauris porttitor, neque feugiat scelerisque consequat , metus dui vehicula tellus, ac tincidunt turpis ligula ut massa. Cras sed rhoncus turpis. Vestibulum sodales est non leo placerat bibendum at ultricies risus. Nulla iaculis lacinia finibus. Sed sit amet metus dignissim, feugiat metus a, pulvinar lectus. Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    rating: 4.5,
    type: "text",
  },
  {
    title: "Kathryn Murphy",
    position: "Strategic Advisor",
    company: " Oprah Winfrey Network",
    review:
      "Mauris porttitor, neque feugiat scelerisque consequat , metus dui vehicula tellus, ac tincidunt turpis ligula ut massa. Cras sed rhoncus turpis. Vestibulum sodales est non leo placerat bibendum at ultricies risus. Nulla iaculis lacinia finibus. Sed sit amet metus dignissim, feugiat metus a, pulvinar lectus. Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    rating: 4.5,
    type: "text",
  },
  {
    type: "image",
    title: "Security & Confidentiality",
    imageUrl: "images/testimonial2.png",
  },
  {
    type: "gradient",
    degree: 0,
  },
];

const thirdColumnData = [
  {
    title: "Kathryn Murphy",
    position: "Strategic Advisor",
    company: " Oprah Winfrey Network",
    review:
      "Mauris porttitor, neque feugiat scelerisque consequat , metus dui vehicula tellus, ac tincidunt turpis ligula ut massa. Cras sed rhoncus turpis. Vestibulum sodales est non leo placerat bibendum at ultricies risus. Nulla iaculis lacinia finibus. Sed sit amet metus dignissim, feugiat metus a, pulvinar lectus. Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    rating: 4.5,
    type: "text",
  },
  {
    type: "image",
    title: "Security & Confidentiality",
    imageUrl: "images/testimonial3.png",
  },
  {
    title: "Kathryn Murphy",
    position: "Strategic Advisor",
    company: " Oprah Winfrey Network",
    review:
      "Mauris porttitor, neque feugiat scelerisque consequat , metus dui vehicula tellus, ac tincidunt turpis ligula ut massa. Cras sed rhoncus turpis. Vestibulum sodales est non leo placerat bibendum at ultricies risus. Nulla iaculis lacinia finibus. Sed sit amet metus dignissim, feugiat metus a, pulvinar lectus. Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    rating: 4.5,
    type: "text",
  },
  {
    title: "Kathryn Murphy",
    position: "Strategic Advisor",
    company: " Oprah Winfrey Network",
    review:
      "Mauris porttitor, neque feugiat scelerisque consequat , metus dui vehicula tellus, ac tincidunt turpis ligula ut massa. Cras sed rhoncus turpis. Vestibulum sodales est non leo placerat bibendum at ultricies risus. Nulla iaculis lacinia finibus. Sed sit amet metus dignissim, feugiat metus a, pulvinar lectus. Description Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    rating: 4.5,
    type: "text",
  },
  {
    type: "image",
    title: "Security & Confidentiality",
    imageUrl: "images/testimonial4.png",
  },
  {
    type: "gradient",
    degree: 0,
  },
];

function CustomerTestimonials() {
  const renderCard = (item) => {
    return (
      <Grid item xs={12} sm={4}>
        <Card
          style={{
            boxShadow: "0px 15px 15px 0px #F3EDE2",
            border: "1px solid #E6E6E6",
            borderRadius: 16,
            padding: "32px",
          }}
        >
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Avatar
                      style={{
                        maxWidth: "100%",
                        // height: "-webkit-fill-available",
                      }}
                      src="images/profileImage.png"
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography
                      variant="h5"
                      style={{
                        fontFamily: "GilroyMedium",
                        fontWeight: 700,
                        fontSize: 20,
                        lineHeight: "24.76px",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontFamily: "GilroyMedium",
                        fontWeight: 500,
                        fontSize: 18,
                        lineHeight: "21.83px",
                      }}
                    >
                      {item.position}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      style={{
                        fontFamily: "GilroyMedium",
                        fontWeight: 500,
                        fontSize: 18,
                        color: "#7E7E86",
                        lineHeight: "21.83px",
                      }}
                    >
                      {item.company}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              {/* Description */}
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  sx={{
                    overflow: "hidden",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 5,
                    fontFamily: "GilroyMedium",
                    fontWeight: 500,
                    fontSize: 16,
                  }}
                >
                  {item.review}
                </Typography>
              </Grid>

              {/* 5 Star Rating */}
              <Grid item xs={12}>
                <CustomRating value={item.rating} />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    );
  };

  const gradientCard = (degree) => {
    return (
      <Hidden mdDown>
        <Grid item xs={12} sm={4}>
          <Card
            style={{
              borderRadius: "16px",
              padding: "54px",
              background: `linear-gradient(${degree}deg, #FEFBF6 0%, #F4EDE2 100%)`,
              boxShadow: "none",
            }}
          ></Card>
        </Grid>
      </Hidden>
    );
  };

  const renderImageCard = (imageUrl, title) => {
    const cardStyle = {
      position: "relative",
      boxShadow: "0px 15px 15px 0px #F3EDE2",
      borderRadius: 16,
      maxWidth: 400,
      backgroundColor: "#FEFBF6",
      overflow: "hidden", // Ensure the overflow is hidden to contain the image and text overlay
    };

    const imageStyle = {
      width: "100%",
      height: "auto", // Set height to auto to maintain aspect ratio
      display: "block", // Ensure the image is a block element
    };

    const overlayStyle = {
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      paddingLeft: "40px",
      paddingBottom: "40px",
      boxSizing: "border-box",
    };

    return (
      <Grid item xs={12} sm={4}>
        <Card style={cardStyle}>
          <img src={imageUrl} alt="Card_background" style={imageStyle} />
          <div style={overlayStyle}>
            <Typography
              sx={{
                fontFamily: "GilroyMedium",
                fontWeight: 700,
                fontSize: 32,
                lineHeight: "39px",
                color: "white",
              }}
            >
              {title}
            </Typography>
          </div>
        </Card>
      </Grid>
    );
  };

  const conditionalRendering = (item) => {
    switch (item.type) {
      case "gradient":
        return gradientCard(item.degree);
      case "image":
        return renderImageCard(item.imageUrl, item.title);
      case "text":
        return renderCard(item);
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        px: { xs: "24px", md: "143px" },
        paddingTop: "60px",
      }}
    >
      <Grid container spacing={4}>
        {/* First grid */}
        <Grid item xs={12} sm={4}>
          <Grid container direction="column" spacing={3}>
            {firstColumnData.map((item) => {
              return conditionalRendering(item);
            })}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Grid container direction="column" spacing={3}>
            {secondColumnData.map((item) => {
              return conditionalRendering(item);
            })}
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Grid container direction="column" spacing={3}>
            {thirdColumnData.map((item) => {
              return conditionalRendering(item);
            })}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default CustomerTestimonials;
