import { Box, Button, Divider, Typography } from "@mui/material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Link } from "react-router-dom";
import { useState } from "react";
import SubscribeDialogue from "./SubscribeDialogue";

const pages = [
  {
    name: "About Us",
    route: "/aboutUs",
    config: {},
  },
  { name: "Data Security", route: "/security", config: {} },
  { name: "Terms and Conditions", route: "/termsAndConditions", config: {} },
  { name: "Privacy Policy", route: "/privacyPolicy", config: {} },
  {
    name: "Data Processing Agreement",
    route: "/dataProcessingAgreement",
    config: {},
  },
  { name: "Data Usage Guidelines", route: "/dataGuidlines", config: {} },
  // { name: "Privacy Policy", route: "", config: {} },
];
const Footer = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <Box
      sx={{
        paddingTop: "80px",
        paddingBottom: "40px",
        backgroundColor: "#171819",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <img
        src={"images/darkThemeLogo.png"}
        alt={"logo"}
        style={{
          width: "208px",
          height: "32px",
          display: "block",
          margin: "auto",
          marginBottom: "40px",
        }}
      />

      <Box className="container">
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            // paddingRight: { xs: "110px", md: "150px" },
            // paddingLeft: { xs: "110px", md: "150px" },
            justifyContent: "center",
            gap: 2,
            marginBottom: "40px",
          }}
        >
          {pages.map((page) => (
            <Button
              key={page}
              sx={{
                color: "#ADAAB0",
                display: "flex",
                textTransform: "none",
                fontWeight: 600,
                fontSize: 15,
                fontFamily: "GilroyMedium",
                lineHeight: "24px",
                // p: 1,
                textAlign: "center",
              }}
              component={Link}
              to={page.route}
              {...page.config}
            >
              {page.name}
            </Button>
          ))}
          {/* <Button
            key={"subscribe"}
            sx={{
              color: "#ADAAB0",
              // display: "block",
              textTransform: "none",
              fontWeight: 600,
              fontSize: 15,
              fontFamily: "GilroyMedium",
              lineHeight: "24px",
              // p: 1,
            }}
            onClick={() => {
              setOpenModal(true);
            }}
          >
            Subscribe
          </Button> */}
        </Box>

        <Divider
          sx={{
            borderColor: "#2C2E30",
            // mx: { xs: "24px", md: "220px" }
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { xs: "column", md: "row" },
            paddingTop: "50px",
            alignItems: "center",
            // mx: { xs: "24px", md: "220px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: { xs: 6, md: 1 },
              mb: { xs: "40px", md: 0 },
            }}
          >
            <LinkedInIcon
              sx={{
                color: "#ADAAB0",
                cursor: "pointer",
                width: { xs: 50, md: 30 },
                height: { xs: 50, md: 30 },
              }}
              onClick={() => {
                window.open("https://www.linkedin.com/company/clinicalpad/");
              }}
            />
            <InstagramIcon
              sx={{
                color: "#ADAAB0",
                cursor: "pointer",
                width: { xs: 50, md: 30 },
                height: { xs: 50, md: 30 },
              }}
              onClick={() => {
                window.open("https://www.instagram.com/clinicalpad/");
              }}
            />
          </Box>
          <Typography
            sx={{
              color: "#ADAAB0",
              fontWeight: 600,
              fontSize: 15,
              fontFamily: "GilroyMedium",
              textAlign: { xs: "center", md: "end" },
              lineHeight: { xs: "32px", md: "16px" },
            }}
          >
            Copyright 2024 | All Rights Reserved ClinicalPad Ltd.
          </Typography>
        </Box>
      </Box>
      <SubscribeDialogue open={openModal} onClose={() => setOpenModal(false)} />
    </Box>
  );
};
export default Footer;
