import { Typography, Hidden, Box } from "@mui/material";
import Footer from "../component/Footer";
import Header from "../component/Header";
import TermsCondition from "../component/TermsCondition";
import termsAndConditions from "../utils/termsAndConditions.json";

const TermsConditions = () => {
  return (
    <Box style={{ backgroundColor: "#FEFBF6" }}>
      <Header />

      {/* SECTION 1 */}
      <Box
        sx={{
          textAlign: "center",
          pt: { xs: "30px", md: "40px" },
          pb: { xs: "40px", md: "80px" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          className="container"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Hidden mdDown>
            <img src="images/termsConditionsHeader.png" alt="header1"></img>
          </Hidden>
          <Typography
            sx={{
              fontFamily: "GilroyMedium",
              fontWeight: 700,
              fontSize: { xs: "40px", md: "65px" },
              lineHeight: { xs: 1.4, md: "75px" },
              ml: { md: 5 },
            }}
          >
            Terms & Conditions
          </Typography>
        </Box>

        <Typography
          className="container"
          sx={{
            fontFamily: "GilroyMedium",
            fontWeight: 500,
            fontSize: 22,
            lineHeight: "26px",
            mt: "17px",
            textAlign: "center",
            // pl: { xs: "24px", md: "360px" },
            // pr: { xs: "24px", md: "210px" },
          }}
        >
          Your attention is particularly drawn to the provisions of clause 4
          (Disclaimers and limitation of Liability) and the indemnity at clause
          12.4
        </Typography>
      </Box>

      <TermsCondition data={termsAndConditions} />
      <Footer />
    </Box>
  );
};

export default TermsConditions;
