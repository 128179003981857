import { Typography, Hidden, Box } from "@mui/material";
import Footer from "../component/Footer";
import Header from "../component/Header";
import DocumentTable from "../component/DocumentView";
import dataAgreement from "../utils/dataAgreement.json";
import TermsCondition from "../component/TermsCondition";

const DataProcessingAgreement = () => {
  return (
    <Box style={{ backgroundColor: "#FEFBF6" }}>
      <Header />

      {/* SECTION 1 */}
      <Box
        sx={{
          textAlign: "center",
          pt: { xs: "30px", md: "40px" },
          pb: { xs: "20px", md: "40px" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          className="container"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Hidden mdDown>
            <img src="images/termsConditionsHeader.png" alt="header1"></img>
          </Hidden>
          <Typography
            sx={{
              fontFamily: "GilroyMedium",
              fontWeight: 700,
              fontSize: { xs: "40px", md: "65px" },
              lineHeight: { xs: 1.4, md: "75px" },
              ml: { md: 5 },
            }}
          >
            Data Processing Agreement
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Typography
          className="container"
          sx={{
            fontFamily: "GilroyMedium",
            fontWeight: 500,
            fontSize: 18,
            lineHeight: "32px",
            pb: "20px",
            textAlign: "center", // Added textAlign: "center" to center-align the content
          }}
          dangerouslySetInnerHTML={{
            __html:
              '<p><span style="font-weight: 400;">This Data Processing Agreement (&ldquo;</span><strong>Agreement</strong><span style="font-weight: 400;">&ldquo;) forms part of the Contract for Services (&ldquo;</span><strong>Principal Agreement</strong><span style="font-weight: 400;">&ldquo;) between</span></p>' +
              '<p><span style="font-weight: 400;">(the &ldquo;</span><strong>Company/User</strong><span style="font-weight: 400;">&rdquo;)</span></p>' +
              '<p><span style="font-weight: 400;">and</span></p>' +
              '<p><span style="font-weight: 400;">(the &ldquo;CLINICALPAD LTD,- Data Processor&rdquo;)</span></p>' +
              '<p><span style="font-weight: 400;">(Together as the &ldquo;</span><strong>Parties</strong><span style="font-weight: 400;">&rdquo;)</span></p>' +
              '<p><span style="font-weight: 400;">WHEREAS</span></p>',
          }}
        />
        <Typography
          className="container"
          sx={{
            fontFamily: "GilroyMedium",
            fontWeight: 500,
            fontSize: 18,
            lineHeight: "32px",
            pb: "20px",
          }}
          dangerouslySetInnerHTML={{
            __html:
              '<p><span style="font-weight: 400;">(A) The Company/User acts as a Data Controller.</span></p>' +
              '<p><span style="font-weight: 400;">(B) The Company/User wishes to subcontract certain Services, which imply the processing of personal data, to the Data Processor.</span></p>' +
              '<p><span style="font-weight: 400;">(C) The Parties seek to implement a data processing agreement that complies with the requirements of the current legal framework in relation to data processing and with the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation).</span></p>' +
              '<p><span style="font-weight: 400;">(D) The Parties wish to lay down their rights and obligations.</span></p>' +
              "<p></p>" +
              '<p><span style="font-weight: 400;">IT IS AGREED AS FOLLOWS:</span></p>',
          }}
        />
      </Box>

      <TermsCondition data={dataAgreement} />

      <Footer />
    </Box>
  );
};

export default DataProcessingAgreement;
