// In Home.js
import React from "react";
import Header from "../component/Header";
import FirstSection from "../component/homepage/FirstSection";
import HomeImageWithText from "../component/HomeImageWithText";
import Box from "@mui/material/Box";
import BenefitsSection from "../component/homepage/BenefitsSection";
import HighlightSection from "../component/homepage/HighlightSection";
import JoinProduct from "../component/JoinProduct";
import ContactUs from "../component/ContactUs";
import Footer from "../component/Footer";
import useMediaQuery from "@mui/material/useMediaQuery";

const Home = () => {
  const redirectToIndividual = () => {};
  const isSmallScreen = useMediaQuery("(max-width: 769px)");

  return (
    <Box style={{ backgroundColor: "#FEFBF6" }}>
      <Header />
      <FirstSection />
      <img
        src={"images/home/home1.jpg"}
        alt={"section2_image"}
        style={{
          // width: "-webkit-fill-available",
          // height: "-webkit-fill-available",
          width: "100%",
          height: "100%"
        }}
      />
      <Box
        sx={{
          backgroundColor: "#FEFBF6",
        }}
      >
        <HomeImageWithText
          imageUrl={
            isSmallScreen
              ? "images/home/homeXS2.png"
              : "images/home/home2.png"
          }
          heading="Ease of Note Taking"
          paragraph="ClinicalPad is embedded with a user-friendly online document creator. This intuitive feature makes documenting patient records and writing prescriptions seamless. Clinicians can now focus on providing excellent health care without the hassle of tedious paperwork."
          imagePosition="right"
          hideButton={false}
          redirectToIndividual={() => redirectToIndividual()}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: "#FCF5E9",
        }}
      >
        <HomeImageWithText
          imageUrl={
            isSmallScreen
              ? "images/home/homeXS3.png"
              : "images/home/home3.png"
          }
          heading="Template Selection"
          paragraph="Medical professionals can now choose from various templates to create customised medical records. This empowers them to draft personalised documents tailored to meet their needs. From selecting the letterhead to picking a good font, it ensures every document is specialised!"
          imagePosition="left"
          backgroundColor="#FCF5E9"
          hideButton={false}
          redirectToIndividual={() => redirectToIndividual()}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: "#FEFBF6",
        }}
      >
        <HomeImageWithText
          imageUrl={
            isSmallScreen
              ? "images/home/homeXS4.png"
              : "images/home/home4.png"
          }
          heading="Letter Customisation"
          paragraph="ClinicalPad aims to facilitate medical professionals from all over the globe! Clinicians can create letters according to their organisation’s and patient's needs. All page elements can be tailored to create a unique letter from top to bottom."
          imagePosition="right"
          backgroundColor="#FEFBF6"
          hideButton={false}
          redirectToIndividual={() => redirectToIndividual()}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: "#FCF5E9",
        }}
      >
        <BenefitsSection />
      </Box>
      <HighlightSection />
      <JoinProduct />
      <ContactUs />
      <Footer />
    </Box>
  );
};

export default Home;
