import React from "react";

const MailchimpForm = () => {
  return (
    <div id="mc_embed_shell" style={{ backgroundColor: "#fff" }}>
      <link
        href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
        rel="stylesheet"
        type="text/css"
      />
      <div
        id="mc_embed_signup"
        style={{ backgroundColor: "#F9EDD7CC" }}
        className="subscribeModal"
      >
        <form
          action="https://clinicalpad.us21.list-manage.com/subscribe/post?u=824161571b3105a6821f64795&amp;id=c6f275446c&amp;f_id=008efee6f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          style={{ margin: 0 }}
        >
          <div id="mc_embed_signup_scroll">
            <div className="indicates-required">
              <span className="asterisk">*</span> indicates required
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-EMAIL">
                Email Address <span className="asterisk">*</span>
              </label>
              <input
                type="email"
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
                required
              />
            </div>
            <div hidden="">
              <input type="hidden" name="tags" value="2900195" />
            </div>
            <div id="mce-responses" className="clear">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: "none" }}
              ></div>
              <div
                className="response"
                id="mce-success-response"
                style={{ display: "none" }}
              ></div>
            </div>
            <div
              aria-hidden="true"
              style={{ position: "absolute", left: "-5000px" }}
            >
              <input
                type="text"
                name="b_824161571b3105a6821f64795_c6f275446c"
                tabIndex="-1"
                value=""
              />
            </div>
            <div className="clear">
              <input
                type="submit"
                name="submit"
                id="mc-embedded-subscribe"
                value="Submit"
                style={{
                  cursor: "pointer",
                  borderRadius: "100px",
                  padding: "11px 32px 11px 32px", // Adjusted padding
                  backgroundColor: "#171819",
                  color: "#fff",
                  textTransform: "none",
                  fontSize: "16px",
                  fontWeight: 700,
                  boxShadow: "none",
                  fontFamily: "PlusJakartaSans",
                  margin: 0,
                }}
              />
            </div>
          </div>
        </form>
      </div>
      <script
        type="text/javascript"
        src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
      ></script>
      <script type="text/javascript">
        {`(function($) {
          window.fnames = new Array();
          window.ftypes = new Array();
          fnames[0]='EMAIL';
          ftypes[0]='email';
          fnames[1]='FNAME';
          ftypes[1]='text';
          fnames[2]='LNAME';
          ftypes[2]='text';
          fnames[3]='ADDRESS';
          ftypes[3]='address';
          fnames[4]='PHONE';
          ftypes[4]='phone';
          fnames[5]='BIRTHDAY';
          ftypes[5]='birthday';
        })(jQuery); var $mcj = jQuery.noConflict(true);`}
      </script>
    </div>
  );
};

export default MailchimpForm;
