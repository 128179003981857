import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
const MyAccordion = ({ label, description, descriptionNode }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleAccordion = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      expanded={expanded}
      onChange={toggleAccordion}
      sx={{ backgroundColor: "#F5F1EE", mt: "16px", boxShadow: "none" }}
    >
      <AccordionSummary expandIcon={expanded ? <RemoveIcon /> : <AddIcon />}>
        <Typography
          sx={{
            fontFamily: "GilroyMedium",
            fontWeight: 600,
            fontSize: { xs: "18px", md: "22px" },
            lineHeight: "28px",
          }}
        >
          {label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {description?.length > 0 &&
          description?.map((item) => {
            return (
              <Typography
                sx={{
                  fontFamily: "GilroyMedium",
                  fontWeight: 500,
                  fontSize: { xs: "16px", md: "18px" },
                  lineHeight: "25px",
                  mb: 1,
                }}
              >
                {item}
              </Typography>
            );
          })}

        {descriptionNode ? (
          <Typography
            sx={{
              fontFamily: "GilroyMedium",
              fontWeight: 500,
              fontSize: "18px",
              lineHeight: "25px",
              mb: 1,
            }}
          >
            {descriptionNode}
          </Typography>
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};

export default MyAccordion;
